import React from 'react'
import Youtube from 'react-youtube'

const Test =  ({ youtubeId }) => {
  return (
    <div
      className="video"
      style={{
        position: "relative",
        paddingBottom: "56.25%" /* 16:9 */,
        paddingTop: 25,
        height: 0
      }}
    >
      <iframe
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%"
        }}
        src={`https://www.youtube.com/embed/${youtubeId}`}
        frameBorder="0"
      />
    </div>
  );
};

// title size

const Chai = () => {

    return (
        <div>
            <h1>Chai</h1>
            <h2>A performed psychogeography</h2>
            <Test youtubeId="4IGknfwvae8"/>
            <br></br>
            This is a video of a live site-specific and site-responsive performance that  
            took place on Monday 22nd of November. The piece is an exploration of three 
            international student’s relationship with Bristol and the UK. 
<br/><br/>
            Experience our demolition and construction of memories, 
            experiences and perception, and the alienation, nostalgia and 
            vulnerability when you’re far away from…home. 

        </div>
    )

}

export default Chai;
